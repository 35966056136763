<template>
  <div class="params">
    <div class="table-btns">
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
    </a-form-model>
  </div>
</template>

<script>
import {
  getDeviceAudioparamValueList,
  getDeviceparamChildLisByParentId, setDeviceAudioparamValueList,
} from "A/ai";
export default {
  name: "deviceinfo",
  data() {
    return {
      id:0,
      deviceName:[
        {
          name: "deviceName",
          id: 0,
          ro: false,
          value: ""
        }
      ],
      telecontrolID:[
        {
          name: "telecontrolID",
          id: 1,
          ro: false,
          value: null
        }
      ],
      model:[
        {
          name: "model",
          id: 2,
          ro: true,
          value: ""
        }
      ],
      methodList: [],
      tabKey: '529',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'GET',
                url: '/system/device-info',
              }
            }
          ]
        };
        getDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.deviceName=res.item[0].params.body[0]
            this.telecontrolID=res.item[0].params.body[1]
            this.model=res.item[0].params.body[2]
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig(){
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'PUT',
                url:'/system/device-info',
                body: this.deviceName,
              }
            },
            {
              params: {
                method: 'PUT',
                url:'/system/device-info',
                body: this.telecontrolID,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            this.$message.success('操作成功');
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>